'use strict';
/**
* Ilduomo Module
*
* Servicio de clientes
*/
Ilduomo.service('inventariosService',inventariosService);
function inventariosService($http, $state){
	  console.log('Inside inventariosService');
    var _DIR_ = "api/";
    return {
    	getViewModalInventario: function (success, failure) {
    	    $http.get(_DIR_+"view/modal/inventario")
    	        .then(success)
    	        .then(failure);
    	}
    }

}