'use strict'
/**
 * Ilduomo Module
 */
Ilduomo.service('socket', socketService)
function socketService($http, $state) {
  //var socket = io('http://localhost:8890')
  var socket = io('https://spacanino.net:8890')
  return {
    on: function (room, success) {
      socket.on(room, success)
    },
    subscribe: function (channel) {
      socket.emit('subscribe', channel)
    },
    emit: function (eventName, channel) {
      socket.emit(eventName, channel)
    }
  }
};
