;(function(window) {
    'use strict'
    /**
     * Ilduomo Module
     *
     * Servicios de datos traidos desde el servidor
     * aqui se definirán todos los getters y setter para ir por la información al
     * servidor o API
     */
    angular.module('Ilduomo').service('ubicacionService', ubicacionService)

    function ubicacionService($http, $state, $auth) {
        console.log('Inside ubicacionService')
        var _DIR_ = 'api/'
        return {
            getPaises: function(success, failure) {
                $http
                    .get(_DIR_ + 'pais')
                    .then(success)
                    .catch(failure)
            },
            getEntidades: function(success, failure) {
                $http
                    .get(_DIR_ + 'entidad')
                    .then(success)
                    .catch(failure)
            },
            getMunicipios: function(success, failure) {
                $http
                    .get(_DIR_ + 'municipio')
                    .then(success)
                    .catch(failure)
            },
            getAreas: function(success, failure) {
                $http
                    .get(_DIR_ + 'area')
                    .then(success)
                    .catch(failure)
            },
            getZonas: function(success, failure) {
                $http
                    .get(_DIR_ + 'zona')
                    .then(success)
                    .catch(failure)
            },
            getColonias: function(success, failure) {
                $http
                    .get(_DIR_ + 'colonia')
                    .then(success)
                    .catch(failure)
            },
            getColoniasMunicipio: function(MunicipioId, success, failure) {
                $http
                    .get(_DIR_ + 'colonia/municipio/' + MunicipioId)
                    .then(success)
                    .catch(failure)
            },
            getMunicipiosZona: function(ZonaId, success, failure) {
                $http
                    .get(_DIR_ + 'municipio/zona/' + ZonaId)
                    .then(success)
                    .catch(failure)
            },
            getTiposDirecciones: function(MunicipioId, success, failure) {
                $http
                    .get(_DIR_ + 'colonia/municipio/' + MunicipioId)
                    .then(success)
                    .catch(failure)
            },
            getCurrentPosition: function(camionetaId, success, failure) {
                $http
                    .get(_DIR_ + 'current-position/' + camionetaId)
                    .then(success)
                    .catch(failure)
            }, 
            getLocations: function(camionetaId, fecha, success, failure) {
                $http
                    .get(_DIR_ + 'locations/user/' + camionetaId + '/' + fecha)
                    .then(success)
                    .catch(failure)
            }, 
        }
    }
})(window)
