;(function(window) {
    'use strict'
    /**
     * Ilduomo Module
     *
     *
     */
    angular.module('Ilduomo').service('telefonosService', telefonosService)

    function telefonosService($http, $state) {
        var _DIR_ = 'api/'
        return {
            getTipos: function(success, failure) {
                $http
                    .get(_DIR_ + 'tipo_telefono')
                    .then(success)
                    .catch(failure)
            },
            dropTelefono: function(telefono, success, failure) {
                $http
                    .post(_DIR_ + 'telefono/' + telefono._id, telefono)
                    .then(success)
                    .catch(failure)
            }
        }
    }
})(window)
