'use strict'

Ilduomo.service('gerenteOperacionesService', function($http, $state, $auth) {
    // console.log('Inside gerenteOperacionesService');
    var _DIR_ = 'api/'
    return {
        getActividadesGerente: function(success, failure) {
            $http
                .get(_DIR_ + 'actividadesGerente')
                .then(success)
                .catch(failure)
        },
        getActividadesOperador: function(success, failure) {
            $http
                .get(_DIR_ + 'actividadesOperador')
                .then(success)
                .catch(failure)
        },
        setActividadesGerente: function(actividades, success, failure) {
            $http
                .post(_DIR_ + 'update/actividadesGerente', actividades)
                .then(success)
                .catch(failure)
        },
        setActividadesOperador: function(actividades, success, failure) {
            $http
                .post(_DIR_ + 'update/actividadesOperador', actividades)
                .then(success)
                .catch(failure)
        }
    }
})
