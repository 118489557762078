'use strict'

Ilduomo.service('userService', function($http, $state, $auth) {
  console.log('Inside userService')
  return {
    getUserRols: function(UserId, success, failure) {
      $http
        .get('api/user_rol/' + UserId)
        .then(success)
        .catch(failure)
    }
  }
})
