'use strict';
/**
* Ilduomo Module
*
* Servicio de clientes
*/
Ilduomo.service('inventarioActualService',inventarioActualService);
function inventarioActualService($http, $state){
    console.log('Inside inventarioActualService');
    var _DIR_ = "api/";
    return {
        saveProducto: function (productoStock ,success, failure) {
            $http.post(_DIR_+"inventarioActual", productoStock)
                .then(success)
                .then(failure);
        },
        getProductos: function (success, failure) {
            $http.get(_DIR_+"inventarioActual")
                .then(success)
                .then(failure);
        },
        getProductosUrl: function (url,success, failure) {
            $http.get(url)
                .then(success)
                .then(failure);
        },
        updateProducto: function(producto,success,failure){
            $http.post(_DIR_+"inventarioActual/"+producto._id, producto)
                .then(success)
                .then(failure);
        },
        dropProducto: function(producto,success,failure){
            $http.post(_DIR_+"inventarioActual/"+producto._id, producto)
                .then(success)
                .then(failure);
        },
        getProductosPorPedir:  function(success,failure){
            $http.get(_DIR_+"inventario/porPedir")
                .then(success)
                .then(failure);
        },
        descartarProducto: function(idServicio,producto,success,failure){
            $http.post(_DIR_+"inventario/descartar/"+idServicio+"/"+producto.productoPrecioId,producto)
                .then(success)
                .then(failure);
        },
        mandarPorPedir: function(idServicio,producto,success,failure){
            $http.post(_DIR_+"inventario/addPorPedir/"+idServicio+"/"+producto.productoPrecioId,producto)
                .then(success)
                .then(failure);
        },
        discardRouteProduct: function(productoId,producto,success,failure){
            $http.post(_DIR_+"inventario/discardRouteProduct/"+productoId,producto)
        }
    }
}