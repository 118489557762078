'use strict';
/**
* Ilduomo Module
*
* Servicio de clientes
*/
Ilduomo.service('inventarioPedidoService',inventarioPedidoService);
function inventarioPedidoService($http, $state){
    console.log('Inside inventarioPedidoService');
    var _DIR_ = "api/";
    return {
        saveProducto: function (productoPedido ,success, failure) {
            $http.post(_DIR_+"inventarioPedido", productoPedido)
                .then(success)
                .then(failure);
        },
        getProductos: function (success, failure) {
            $http.get(_DIR_+"inventarioPedido")
                .then(success)
                .then(failure);
        },
        getProductosUrl: function (url,success, failure) {
            $http.get(url)
                .then(success)
                .then(failure);
        },
        updateProducto: function(producto,success,failure){
            $http.post(_DIR_+"inventarioPedido/" + producto._id, producto)
                .then(success)
                .then(failure);
        },
        dropProducto: function(producto,success,failure){
            $http.post(_DIR_+"inventarioPedido/"+producto._id, producto)
                .then(success)
                .then(failure);
        },
        confirmRecepcion: function(producto,success,failure){
            $http.post(_DIR_+"confirmarPedido/producto/" + producto._id, producto)
                .then(success)
                .then(failure);
        }
    }
}