;(function(window) {
    'use strict'
    /**
     * Ilduomo Module
     *
     * Servicios de datos traidos desde el servidor
     * aqui se definirán todos los getters y setter para ir por la información al
     * servidor o API
     */
    angular.module('Ilduomo').service('titulosService', titulosService)

    function titulosService($http, $state, $auth) {
        console.log('Inside titulosService')
        var _DIR_ = 'api/'
        return {
            getTitulos: function(success, failure) {
                $http
                    .get(_DIR_ + 'titulo')
                    .then(success)
                    .catch(failure)
            },
            getParentescos: function(success, failure) {
                $http
                    .get(_DIR_ + 'parentescos')
                    .then(success)
                    .catch(failure)
            }
        }
    }
})(window)
