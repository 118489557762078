'use strict';
/**
* Ilduomo Module
*
* Servicio de clientes
*/
Ilduomo.service('inventarioRecepcionService',inventarioRecepcionService);
function inventarioRecepcionService($http, $state){
    console.log('Inside inventarioRecepcionService');
    var _DIR_ = "api/";
    return {

        savePedido: function (productoPedido ,success, failure) {
            $http.post(_DIR_+"inventarioRecepcion", productoPedido)
                .then(success)
                .then(failure);
        },

        getPedidos: function (success, failure) {
            $http.get(_DIR_+"inventarioRecepcion")
                .then(success)
                .then(failure);
        },

        updatePedido: function(productoPedido,success,failure){
            $http.post(_DIR_+"inventarioRecepcion/"+productoPedido._id, productoPedido)
                .then(success)
                .then(failure);
        }
    }
}