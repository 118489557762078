'use strict'
/**
 * Ilduomo Module
 *
 * Servicio de clientes
 */
Ilduomo.service('clienteService', clienteService)
function clienteService($http, $state, $auth) {
    // console.log('Inside clienteService');
    var _DIR_ = 'api/'
    return {
        agendar: function(servicios, success, failure) {
            $http
                .post(_DIR_ + 'agendar', servicios)
                .then(success)
                .catch(failure)
        },
        Reagendar: function(servicioId, servicios, success, failure) {
            $http
                .post(_DIR_ + 'agendar/' + servicioId, servicios)
                .then(success)
                .catch(failure)
        },
        getHistoricoServicio: function(clienteId, negocioId, success, failure) {
            $http
                .get(
                    _DIR_ + 'agendado/historico/' + clienteId + '/' + negocioId
                )
                .then(success)
                .catch(failure)
        },
        getModalContactoInfo: function(success, failure) {
            $http
                .get(_DIR_ + 'view/modal/editarContacto')
                .then(success)
                .catch(failure)
        },
        getModalBuscarClientes: function(success, failure) {
            $http
                .get(_DIR_ + 'view/modal/buscarClientes')
                .then(success)
                .catch(failure)
        },
        busqueda: function(buscar, success, failure) {
            $http
                .get(
                    _DIR_ +
                        'clientes/negocio/' +
                        buscar.negocioCliente +
                        '/estatus/' +
                        buscar.estatusClienteId +
                        '/municipio/' +
                        buscar.municipioClienteId +
                        '/zona/' +
                        buscar.zonaClienteId +
                        '/colonia/' +
                        buscar.coloniaClienteId +
                        '/desdePeriodicidad/' +
                        buscar.desdePeriodicidadParse +
                        '/hastaPeriodicidad/' +
                        buscar.hastaPeriodicidadParse +
                        '/desdeProgramada/' +
                        buscar.desdeLlamadaProgramadaParse +
                        '/hastaProgramada/' +
                        buscar.hastaLlamadaProgramadaParse +
                        '/ordenarZona/' +
                        buscar.ordenarZona +
                        '/ordenarColonia/' +
                        buscar.ordenarColonia +
                        '/ordenarCalle/' +
                        buscar.ordenarCalle +
                        '/buscar/' +
                        buscar.busqueda +
                        '/tipo/' +
                        buscar.tipo
                )
                .then(success)
                .catch(failure)
            // /nombre/"+buscar.nombreCliente+
            //                     "/apellido/"+buscar.apellidoCliente+
            //                     "/correo/"+buscar.correoCliente+
            //                     "/telefono/"+buscar.telefonoCliente+
            // "/entidad/"+buscar.entidadClienteId+
            // "/mascota/"+buscar.nombreMascota+
            //                     "/raza/"+buscar.razaMascotaId+
            //                     "/marca/"+buscar.marcaAlimentoId+
            //                     "/especie/"+buscar.especieMascotaId+
            //                     "/servicio/"+buscar.servicioMascotaId+
            //                     "/calle/"+buscar.calleCliente
        },
        setAlerta: function(alerta, success, failure) {
            $http
                .post(_DIR_ + 'alerta', alerta)
                .then(success)
                .catch(failure)
        },
        getClientes: function(p, success, failure) {
            $http
                .get(_DIR_ + 'cliente?page=' + p)
                .then(success)
                .catch(failure)
        },
        getClientesUrl: function(url, success, failure) {
            $http
                .get(url)
                .then(success)
                .catch(failure)
        },
        getCliente: function(ClienteId, success, failure) {
            $http
                .get(_DIR_ + 'cliente/' + ClienteId)
                .then(success)
                .catch(failure)
        },
        saveCliente: function(cliente, success, failure) {
            $http
                .post(_DIR_ + 'cliente', cliente)
                .then(success)
                .catch(failure)
        },
        updateCliente: function(cliente, success, failure) {
            $http
                .post(_DIR_ + 'cliente/' + cliente._id, cliente)
                .then(success)
                .catch(failure)
        },
        updatePeriodicidad: function(periodicidad, success, failure) {
            $http
                .post(_DIR_ + 'periodicidad/' + periodicidad._id, periodicidad)
                .then(success)
                .catch(failure)
        },
        updateTelefonosCliente: function(cliente, lista, success, failure) {
            $http
                .post(_DIR_ + 'cliente/telefono/' + cliente._id, lista)
                .then(success)
                .catch(failure)
        },
        updateNota: function(clienteId, nota, success, failure) {
            $http
                .post(_DIR_ + 'cliente/nota/' + clienteId, nota)
                .then(success)
                .catch(failure)
        },
        updateInfo: function(cliente, success, failure) {
            $http
                .post(_DIR_ + 'cliente/informacion/' + cliente._id, cliente)
                .then(success)
                .catch(failure)
        },
        updateContactos: function(cliente, success, failure) {
            $http
                .post(_DIR_ + 'cliente/' + cliente._id + '/contacto', cliente)
                .then(success)
                .catch(failure)
        },
        updateUbicacion: function(cliente, success, failure) {
            $http
                .post(_DIR_ + 'cliente/' + cliente._id + '/ubicacion', cliente)
                .then(success)
                .catch(failure)
        },
        updateMaterial: function(clienteId, equipmentNote, success, failure){
            $http
                .post(_DIR_ + 'cliente/' + clienteId + '/equipmentNote', equipmentNote)
                .then(success)
                .catch(failure)
        },
        dropCliente: function(cliente, success, failure) {
            $http
                .post(_DIR_ + 'cliente/' + cliente._id, cliente)
                .then(success)
                .catch(failure)
        },
        dropContacto: function(contacto, success, failure) {
            $http
                .post(_DIR_ + 'cliente/contacto/' + contacto._id, contacto)
                .then(success)
                .catch(failure)
        },
        dropMascota: function(mascota, success, failure) {
            $http
                .post(_DIR_ + 'mascota/' + mascota._id, mascota)
                .then(success)
                .catch(failure)
        },
        getEspecies: function(success, failure) {
            $http
                .get(_DIR_ + 'especie')
                .then(success)
                .catch(failure)
        },
        getRazas: function(success, failure) {
            $http
                .get(_DIR_ + 'raza')
                .then(success)
                .catch(failure)
        },
        getSemanas: function(success, failure) {
            $http
                .get(_DIR_ + 'semanaruta')
                .then(success)
                .catch(failure)
        },
        getNegocios: function(success, failure) {
            $http
                .get(_DIR_ + 'negocio')
                .then(success)
                .catch(failure)
        },
        getRazasEspecie: function(especieId, success, failure) {
            $http
                .get(_DIR_ + 'raza/especie/' + especieId)
                .then(success)
                .catch(failure)
        },
        getTamanioMascota: function(success, failure) {
            $http
                .get(_DIR_ + 'tamaniomascota')
                .then(success)
                .catch(failure)
        },
        getEdadesMascota: function() {
            return [
                {
                    Nombre: '1 Mes'
                },
                {
                    Nombre: '2 Mes'
                },
                {
                    Nombre: '3 Mes'
                },
                {
                    Nombre: '4 Mes'
                },
                {
                    Nombre: '5 Mes'
                },
                {
                    Nombre: '6 Mes'
                },
                {
                    Nombre: '7 Mes'
                },
                {
                    Nombre: '8 Mes'
                },
                {
                    Nombre: '9 Mes'
                },
                {
                    Nombre: '10 Mes'
                },
                {
                    Nombre: '11 Mes'
                },
                {
                    Nombre: '1 Año'
                },
                {
                    Nombre: '2 Año'
                },
                {
                    Nombre: '3 Año'
                },
                {
                    Nombre: '4 Año'
                },
                {
                    Nombre: '5 Año'
                },
                {
                    Nombre: '6 Año'
                },
                {
                    Nombre: '7 Año'
                },
                {
                    Nombre: '8 Año'
                },
                {
                    Nombre: '9 Año'
                },
                {
                    Nombre: '10 Año'
                },
                {
                    Nombre: '11 Año'
                },
                {
                    Nombre: '12 Año'
                },
                {
                    Nombre: '13 Año'
                },
                {
                    Nombre: '14 Año'
                }
            ]
        },
        getSexoMascotas: function() {
            return [
                {
                    Nombre: 'Macho'
                },
                {
                    Nombre: 'Hembra'
                },
                {
                    Nombre: 'Por definir'
                }
            ]
        },
        getPeriodicidades: function(cliente, success, failure) {
            $http
                .get(_DIR_ + "cliente/" + cliente.clienteId + '/negocio/' + cliente.negocioId)
                .then(success)
                .then(failure)
        },
        dropPeriodicidadProducto: function (periodicidadId, success, failure) {
            $http
                .get(_DIR_ + "periodicidad/" + periodicidadId + '/delete')
                .then(success)
                .then(failure)
        },
        updatePeriodicidadConjunto: function(periodicidad,success,failure){
            $http
                .post(_DIR_+'periodicidadConjunto/update/'+ periodicidad.ClienteId, periodicidad)
                .then(success)
                .then(failure)
        }

    }
}
