'use strict'
/**
 * Ilduomo Module
 *
 * Servicios de datos traidos desde el servidor
 * aqui se definirán todos los getters y setter para ir por la información al
 * servidor o API
 */
Ilduomo.service('dataService', dataService)

function dataService($http, $state) {
    console.log('Inside dataService')
    var _DIR_ = 'api/'
    return {
        cargarProductos: function(buscar, success, failure) {
            $http
                .get(_DIR_ + 'productos/' + buscar)
                .then(success)
                .catch(failure)
        },
        getFromUrl: function(url, success, failure) {
            $http
                .get(url)
                .then(success)
                .catch(failure)
        },
        getCallFor: function(index, day, assignationType, success, failure) {
            $http
                .get(
                    _DIR_ +
                        'objetivo/getMisLlamadas/day/' +
                        day +
                        '/index/' +
                        index +
                        '/tipo-asignacion/' +
                        assignationType
                )
                .then(success)
                .catch(failure)
        },
        getRemainingCall: function(day,assignationType,success,failure){
            $http
                .get(
                    _DIR_+
                    'objetivo/getRemainingCall/day/'+
                    day +
                    '/tipo-asignacion/'+
                    assignationType
                )
                .then(success)
                .catch(failure)
        },
        getAllNotifications: function(success, failure) {
            $http
                .get(_DIR_ + 'notificaciones')
                .then(success)
                .catch(failure)
        },
        agregaComision: function(comision, success, failure) {
            $http
                .post(_DIR_ + 'comision', comision)
                .then(success)
                .catch(failure)
        },
        borraComision: function(comision, success, failure) {
            $http
                .post(_DIR_ + 'comision/' + comision._id, comision)
                .then(success)
                .catch(failure)
        },
        getComisiondia: function(d, success, failure) {
            $http
                .get(_DIR_ + 'comision/date/' + d)
                .then(success)
                .catch(failure)
        },
        getComisiondediaadia: function(i, f, success, failure) {
            $http
                .get(_DIR_ + 'comision/date/' + i + '/todate/' + f)
                .then(success)
                .catch(failure)
        },
        clienteGenerado: function(success, failure) {
            $http
                .get(_DIR_ + 'clienteGenerado')
                .then(success)
                .catch(failure)
        },
        dropClienteGenerado: function(c, success, failure) {
            $http
                .post(_DIR_ + 'clienteGenerado/' + c._id, c)
                .then(success)
                .catch(failure)
        },
        getCobrosOperadores: function(success, failure) {
            $http
                .get(_DIR_ + 'cobro')
                .then(success)
                .catch(failure)
        },
        saveCobroOperador: function(cobro, success, failure) {
            $http
                .post(_DIR_ + 'cobro', cobro)
                .then(success)
                .catch(failure)
        },
        actualizaCobroOperador: function(cobro, success, failure) {
            $http
                .post(_DIR_ + 'cobro/' + cobro._id, cobro)
                .then(success)
                .catch(failure)
        },
        pagaCobro: function(cobro, success, failure) {
            $http
                .post(_DIR_ + 'cobrado/' + cobro._id, cobro)
                .then(success)
                .catch(failure)
        },
        getPrecioEstetica: function(
            FranId,
            tamId,
            nombreServicio,
            success,
            failure
        ) {
            $http
                .get(
                    _DIR_ +
                        'servicio/precio/' +
                        FranId +
                        '/' +
                        tamId +
                        '/' +
                        nombreServicio
                )
                .then(success)
                .catch(failure)
        },
        getAgregaCliente: function(success, failure) {
            $http
                .get(_DIR_ + 'view/agregaCliente')
                .then(success)
                .catch(failure)
        },
        getAgendar: function(negocioId, success, failure) {
            $http
                .get(_DIR_ + 'view/agendar/negocio/' + negocioId)
                .then(success)
                .catch(failure)
        },
        getPista: function(success, failure) {
            $http
                .get(_DIR_ + 'view/pista')
                .then(success)
                .catch(failure)
        },
        getRutaOperadorView: function(camionetaId, dia, success, failure) {
            $http
                .get(
                    _DIR_ +
                        'view/rutaOperadores/camioneta/' +
                        camionetaId +
                        '/dia/' +
                        dia
                )
                .then(success)
                .catch(failure)
        },
        getRutaOperador: function(camionetaId, dia, success, failure) {
            $http
                .get(_DIR_ + 'agendado/' + camionetaId + '/dia/' + dia)
                .then(success)
                .catch(failure)
        },
        getAlertaCliente: function(cliente, success, failure) {
            $http
                .get(_DIR_ + 'alerta/cliente/' + cliente._id)
                .then(success)
                .catch(failure)
        },
        removeAlert: function(alerta, success, failure) {
            $http
                .post(_DIR_ + 'alerta/' + alerta._id, alerta)
                .then(success)
                .catch(failure)
        },
        agregaLlamadaProgramada: function(llamada, success, failure) {
            $http
                .post(_DIR_ + 'llamadaprogramada', llamada)
                .then(success)
                .catch(failure)
        },
        updateLlamadaProgramada: function(llamada, success, failure) {
            $http
                .post(_DIR_ + 'llamadaprogramada/' + llamada._id, llamada)
                .then(success)
                .catch(failure)
        },
        agregaRespuestaLlamada: function(
            respuesta,
            objetivo,
            success,
            failure
        ) {
            $http
                .post(_DIR_ + 'llamada/objetivo/' + objetivo, respuesta)
                .then(success)
                .catch(failure)
        },
        getRespuestaLlamada: function(success, failure) {
            $http
                .get(_DIR_ + 'resultadollamada')
                .then(success)
                .catch(failure)
        },
        getEstatusLlamada: function(success, failure) {
            $http
                .get(_DIR_ + 'estatusllamada')
                .then(success)
                .catch(failure)
        },
        getEstatusMisLlamadas: function(dia, success, failure) {
            $http
                .get(_DIR_ + 'estatusobjetivo/' + dia)
                .then(success)
                .catch(failure)
        },
        getModalMascota: function(success, failure) {
            $http
                .get(_DIR_ + 'view/modal/mascotas')
                .then(success)
                .catch(failure)
        },
        updateMascotas: function(cliente, success, failure) {
            $http
                .post(_DIR_ + 'cliente/' + cliente._id + '/mascotas', cliente)
                .then(success)
                .catch(failure)
        },
        updateTipoPago: function(agendadoId, tipoPago, success, failure) {
            $http
                .get(_DIR_+ 'agendado/' + agendadoId + '/pago/' + tipoPago)
                .then(success)
                .then(failure)
        },
        getModalUbicacion: function(success, failure) {
            $http
                .get(_DIR_ + 'view/modal/ubicacion')
                .then(success)
                .catch(failure)
        },
        getInformacionOperadores: function(success, failure) {
            $http
                .get(_DIR_ + 'operador')
                .then(success)
                .catch(failure)
        },
        getMarcasAlimento: function(success, failure) {
            $http
                .get(_DIR_ + 'marcaproducto')
                .then(success)
                .catch(failure)
        },
        getAgentesCallcenter: function(success, failure) {
            $http
                .get(_DIR_ + 'users/rol/Agente de C.C.')
                .then(success)
                .catch(failure)
        },
        getProductosNegocio: function(negocioId, success, failure) {
            $http
                .get(_DIR_ + 'stock/' + negocioId)
                .then(success)
                .catch(failure)
        },
        getAgendadoElDia: function(dia, success, failure) {
            $http
                .get(_DIR_ + 'agendado/' + dia)
                .then(success)
                .catch(failure)
        },
        getAgendadoElDiaNegocio: function(dia, negocioId, success, failure) {
            $http
                .get(_DIR_ + 'agendado/' + dia + '/negocio/' + negocioId)
                .then(success)
                .catch(failure)
        },
        getInfoServicio: function(sId, nId, notif, success, failure) {
            $http
                .get(
                    _DIR_ +
                        'agendar/' +
                        sId +
                        '/negocio/' +
                        nId +
                        '/notificacion/' +
                        notif
                )
                .then(success)
                .catch(failure)
        },
        getRazonCancelacion: function(success, failure) {
            $http
                .get(_DIR_ + 'cancelacion_catalogo')
                .then(success)
                .catch(failure)
        },
        cancelacionServicio: function(c, success, failure) {
            $http
                .post(_DIR_ + 'cancelar', c)
                .then(success)
                .catch(failure)
        },
        confirmarServicio: function(c, success, failure) {
            $http
                .post(_DIR_ + 'confirmar/' + c._id, c)
                .then(success)
                .catch(failure)
        },
        cambiaEstatus: function(estatus, servicio, success, failure) {
            $http
                .post(
                    _DIR_ + 'estatus/' + estatus + '/' + servicio._id,
                    servicio
                )
                .then(success)
                .catch(failure)
        },
        getCamionetas: function(success, failure) {
            $http
                .get(_DIR_ + 'camioneta')
                .then(success)
                .catch(failure)
        },
        getCamionetaNegocioId: function(negocioId, success, failure) {
            $http
                .get(_DIR_ + 'camioneta/negocioId/' + negocioId)
                .then(success)
                .catch(failure)
        },
        getGerenteCCView: function(date, success, failure) {
            $http
                .get(_DIR_ + 'view/gerenteCallcenter/' + date)
                .then(success)
                .catch(failure)
        },
        getModalRespuestaLlamada: function(success, failure) {
            $http
                .get(_DIR_ + 'view/modal/respuestallamada')
                .then(success)
                .catch(failure)
        },
        agregarRespuestaLlamadasProgramadas: function(
            rl,
            objetivo,
            success,
            failure
        ) {
            $http
                .post(
                    _DIR_ +
                        'llamadaprogramada/realizada/' +
                        'objetivo/' +
                        objetivo,
                    rl
                )
                .then(success)
                .catch(failure)
        },
        getLlamadaProgramada: function(idLlamadaProgramada, success, failure) {
            $http
                .get(_DIR_ + 'llamadaprogramada/' + idLlamadaProgramada)
                .then(success)
                .catch(failure)
        },
        getObjetivo: function(day, assignationType, success, failure) {
            $http
                .get(
                    _DIR_ +
                        'objetivo/getObjetivo/' +
                        day +
                        '/' +
                        assignationType
                )
                .then(success)
                .catch(failure)
        },
        getMisLlamadas: function(day, assignationType, success, failure) {
            $http
                .get(
                    _DIR_ +
                        'objetivo/getMisLlamadas/day/' +
                        day +
                        '/' +
                        assignationType
                )
                .then(success)
                .catch(failure)
        },
        agregaAsignacionLlamadas: function(asignacion, success, failure) {
            $http
                .post(_DIR_ + 'objetivo/gerentecc', asignacion)
                .then(success)
                .catch(failure)
        },
        deleteAsignacionLlamadas: function(asignacion, success, failure) {
            $http
                .post(_DIR_ + 'objetivo/gerentecc/delete', asignacion)
                .then(success)
                .catch(failure)
        },
        getAllCall: function(clienteId, success, failure) {
            $http
                .get(_DIR_ + 'llamada/cliente/' + clienteId)
                .then(success)
                .catch(failure)
        },
        getNumObligatorias:function(day, success,failure){
            $http
                .get(_DIR_ + 'objetivo/asignaciones/operador/' + day)
                .then(success)
                .catch(failure)
        },
        getAsignadas:function(day,success,failure){
            $http
                .get(_DIR_+ 'objetivo/gerentecc/' + day + '/asignadas')
                .then(success)
                .catch(failure)
        },
        getNegocios: function (success,failure) {
            $http.get(_DIR_+'negocio')
                .then(success)
                .then(failure);
        },
    }
}
